import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

import { loadUser, socialLogin } from '../../actions/auth'
import { useIsMountedRef } from '../utilities/isMounted'
import { Loading } from '../loading/Loading'

function AuthGoogle() {
  const dispatch = useDispatch()
  const isMountedRef = useIsMountedRef()
  const navigate = useNavigate()
  const effectRan = useRef(false)

  async function authenticate() {
    const urlParams = new URLSearchParams(window.location.search)
    const state = urlParams.has('state') ? urlParams.get('state') : null
    const code = urlParams.has('code') ? urlParams.get('code') : null

    if (state && code) {
      try {
        const socialResponse = await dispatch(socialLogin('google-oauth2', state, code))
        const res = await dispatch(loadUser())
        if (res.ok) {
          navigate('/dashboard', { replace: true })
        } else {
          navigate('/login', { replace: true })
        }
      } catch (error) {
        navigate('/login', { replace: true })
      }
    } else {
      navigate('/login', { replace: true })
    }
  }

  /**
   * Used for social auth login. The user on social auth
   * gets redirected here with a state and code param that
   * can be used to authenticate them.
   */
  useEffect(() => {
    if (isMountedRef.current) {
      if (!effectRan.current) {
        authenticate()
      }

      return () => {
        effectRan.current = true
      }
    }
  }, [authenticate])

  return (
      <Box mb={1}>
        <Loading />
      </Box>
  )
}

export default AuthGoogle
