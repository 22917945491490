import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { AppBar, Avatar, Box, Typography, Grid, Paper, CssBaseline, Link } from '@mui/material'

import { authLayout } from '../common/styles'

const styles = theme => ({
  ...authLayout(theme)
})

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {' © '}
      {new Date().getFullYear()}
      &nbsp;
      <Link color="inherit" href="https://www.ploutosbudget.com">
        Ploutos Studios
      </Link>
    </Typography>
  )
}

function AuthLayout(props) {
  const { classes, theme, children, heading } = props

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar} style={{ padding: theme.spacing(2) }} />
      <Grid container className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={6} md={7}>
          <Avatar
            variant="circular"
            className={classes.image}
            alt="Ploutos Budget Logo"
            src="/static/ploutos.svg"
          />
          <Box p={2}>
            <Typography
              variant="h6"
              component="h1"
              align="center"
              noWrap
              style={{
                color: theme.palette.primary.main,
                fontWeight: 400
              }}
            >
              <p
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  fontSize: '2rem',
                  fontFamily: 'Yellowtail, Roboto, Helvetica, Arial, sans-serif'
                }}
              >
                Ploutos Budget
              </p>
              A Better Way To Manage Your Money
            </Typography>
          </Box>
          <Grid
            container
            item
            justifyContent="center"
            xs={9}
            spacing={3}
            className={classes.centerGrid}
          >
            <Grid item xs={12}>
              <Paper className={classes.centerCard} elevation={3}>
                <Box p={2}>
                  <Typography variant="h6">Track all of your accounts</Typography>
                  <Typography variant="body2">
                    Start off by adding all of your spending and saving accounts into your budget.
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.centerCard} elevation={3}>
                <Box p={2}>
                  <Typography variant="h6">Create goals</Typography>
                  <Typography variant="body2">
                    For each month create goals for how much you want to spend in each category.
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.centerCard} elevation={3}>
                <Box p={2}>
                  <Typography variant="h6">Fund categories</Typography>
                  <Typography variant="body2">Budget all of the money in your accounts.</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.centerCard} elevation={3}>
                <Box p={2}>
                  <Typography variant="h6">Spend with confidence</Typography>
                  <Typography variant="body2">
                    Your categories will tell you how much money you have left to spend.
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              {heading}
            </Typography>
            {children}
          </div>
          <Box mt={5} mb={3}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string.isRequired
}

export default withStyles(styles, { withTheme: true })(AuthLayout)
