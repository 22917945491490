import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Typography, TextField, Divider, Button, Grid, Link as Anchor } from '@mui/material'

import { sharedStyles } from '../common/styles'
import { register } from '../../actions/auth'
import { continueWithGoogle } from '../../utilities'
import { createToast } from '../../actions/messages'
import AuthLayout from './AuthLayout'

const styles = theme => ({
  ...sharedStyles(theme),
  container: {
    width: '100%',
    minWidth: 'min-content',
    maxWidth: '400px'
  },
  gsiMaterialButton: {
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    WebkitAppearance: 'none',
    backgroundColor: 'WHITE',
    backgroundImage: 'none',
    border: '1px solid #747775',
    WebkitBorderRadius: '4px',
    borderRadius: '4px',
    WebkitBoxSizing: 'border-box',
    boxSizing: 'border-box',
    color: '#1f1f1f',
    cursor: 'pointer',
    fontFamily: '\'Roboto\', arial, sans-serif',
    fontSize: '14px',
    height: '40px',
    letterSpacing: '0.25px',
    outline: 'none',
    overflow: 'hidden',
    padding: '0 12px',
    position: 'relative',
    textAlign: 'center',
    WebkitTransition:
        'background-color .218s, border-color .218s, box-shadow .218s',
    transition: 'background-color .218s, border-color .218s, box-shadow .218s',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: '100%',
    minWidth: 'min-content',
    maxWidth: '400px'
  },
  gsiMaterialButtonIcon: {
    height: '20px',
    marginRight: '12px',
    minWidth: '20px',
    width: '20px'
  },
  gsiMaterialButtonContentWrapper: {
    WebkitAlignItems: 'center',
    alignItems: 'center',
    display: 'flex',
    WebkitFlexDirection: 'row',
    flexDirection: 'row',
    WebkitFlexWrap: 'nowrap',
    flexWrap: 'nowrap',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%'
  },
  gsiMaterialButtonContents: {
    WebkitFlexGrow: '0',
    flexGrow: 0,
    fontFamily: '\'Roboto\', arial, sans-serif',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top'
  },
  gsiMaterialButtonState: {
    WebkitTransition: 'opacity .218s',
    transition: 'opacity .218s',
    bottom: '0',
    left: '0',
    opacity: 0,
    position: 'absolute',
    right: '0',
    top: '0'
  }
})

class Register extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    rePassword: ''
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onSubmit = e => {
    e.preventDefault()
    const { firstName, lastName, email, password, rePassword } = this.state
    if (password !== rePassword) {
      this.props.createToast({ passwordsNotMatch: 'Passwords do not match' })
    } else {
      const newUser = {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        re_password: rePassword
      }

      this.props.register(newUser)
    }
  }

  render() {
    if (this.props.isAuthenticated) return <Navigate to="/" />
    if (this.props.accountCreated) return <Navigate to="/register-acknowledge" />

    const { firstName, lastName, email, password, rePassword } = this.state
    const { classes } = this.props
    return (
        <AuthLayout heading="Create an account">
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                <TextField
                    required
                    autoComplete="off"
                    onChange={this.onChange}
                    value={firstName}
                    name="firstName"
                    type="text"
                    label="First Name"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    autoComplete="off"
                    onChange={this.onChange}
                    value={lastName}
                    name="lastName"
                    label="Last Name"
                    type="text"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    autoComplete="off"
                    onChange={this.onChange}
                    value={email}
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    autoComplete="off"
                    onChange={this.onChange}
                    value={password}
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    autoComplete="off"
                    onChange={this.onChange}
                    value={rePassword}
                    name="rePassword"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth type="submit" color="primary" variant="contained">
                  <Typography className={classes.pl1} variant="button" align="center" noWrap>
                    Sign up with email
                  </Typography>
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Divider>or</Divider>
              </Grid>

              <Grid item xs={12}>
                <Button fullWidth className={classes.gsiMaterialButton} type="button"
                        onClick={continueWithGoogle}>
                  <div className={classes.gsiMaterialButtonState}></div>
                  <div className={classes.gsiMaterialButtonContentWrapper}>
                    <div className={classes.gsiMaterialButtonIcon}>
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                           xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'block' }}>
                        <path fill="#EA4335"
                              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4"
                              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05"
                              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853"
                              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span className={classes.gsiMaterialButtonContents}>Continue with Google</span>
                    <span style={{ display: 'none' }}>Continue with Google</span>
                  </div>
                </Button>
              </Grid>

              <Grid container item xs={12} justifyContent="center" className={classes.pt1}>
                <Typography variant="body2" noWrap>
                  Already have an account?&nbsp;
                  <Link to="/login" className={classes.textLinks}>
                    Sign in
                  </Link>
                </Typography>
              </Grid>

              <Grid container item xs={12} justifyContent="center" alignItems="center">
                <Typography variant="body2">
                  By creating an account, you agree to our&nbsp;
                </Typography>
                <Typography variant="body2" noWrap>
                  <Anchor
                      underline="always"
                      target="_blank"
                      color="inherit"
                      href="https://www.ploutosbudget.com/terms"
                  >
                    Terms of Use
                  </Anchor>
                </Typography>
                <Typography variant="body2">&nbsp;and&nbsp;</Typography>
                <Typography variant="body2" noWrap>
                  <Anchor
                      underline="always"
                      target="_blank"
                      color="inherit"
                      href="https://www.ploutosbudget.com/privacy"
                  >
                    Privacy Policy
                  </Anchor>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </AuthLayout>
    )
  }
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  accountCreated: PropTypes.bool
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  accountCreated: state.authReducer.accountCreated
})

const mapDispatchToProps = {
  register,
  createToast
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Register))
