import React, { useRef, useEffect } from 'react'

export function useIsMountedRef() {
  const isMountedRef = useRef(null)
  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })
  return isMountedRef
}
