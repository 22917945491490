import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import { Loading } from '../loading/Loading'
import Membership from '../membership/Membership'

const PrivateRoute = ({ component: Component, auth, membership, ...rest }) => {
  let membershipActive = true
  try {
    const customer =
        membership && membership.customer && membership.customer instanceof Array
            ? membership.customer
            : null
    const subscription =
        customer && customer[0] && customer[0].subscriber ? customer[0].subscriber : null
    const member =
        subscription && subscription.subscriber && subscription.subscriber.entitlements.member
            ? subscription.subscriber.entitlements.member
            : null

    const currentDate = DateTime.local().toISODate()

    if (!member || (Object.keys(member).length <= 0) || (member.expires_date < currentDate)) {
      membershipActive = false
    }

  } catch (error) {
    // TODO: handle error
  }

  if (auth.isLoading || membership.isLoading) {
    return <Loading />
  } else if (!auth.isAuthenticated) {
    return <Navigate to="/login" />
  } else if (auth.isAuthenticated && membershipActive) {
    return <Component {...rest} />
  } else {
    return <Membership {...rest} />
  }
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  membership: state.membershipReducer
})

export default connect(mapStateToProps)(PrivateRoute)
