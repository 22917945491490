import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Grid, CssBaseline } from '@mui/material'

import ThemeWrapper from './layout/ThemeWrapper'
import ErrorBoundary from './error/ErrorBoundary'
import Error404 from './error/Error404'
import AuthLoading from './loading/AuthLoading'
import Idle from './common/Idle'
import Header from './layout/Header'
import Alerts from './layout/Alerts'
import Message from './layout/Message'
import ToastWrapper from './layout/ToastWrapper'
import Login from './users/Login'
import AuthGoogle from './users/AuthGoogle'
import Register from './users/Register'
import RegisterAcknowledge from './users/RegisterAcknowledge'
import Activate from './users/Activate'
import ResetPassword from './users/ResetPassword'
import ResetPasswordAcknowledge from './users/ResetPasswordAcknowledge'
import ResetPasswordConfirm from './users/ResetPasswordConfirm'
import SettingsDashboard from './users/SettingsDashboard'
import PrivateRoute from './common/PrivateRoute'
import Dashboard from './dashboard/Dashboard'
import BudgetDashboard from './budget/BudgetDashboard'
import Recurring from './recurring'
import History from './history'
import TransactionDashboard from './transaction/TransactionDashboard'
import HoldingDetail from './holdings/HoldingDetail'
import ReportDashboard from './report/ReportDashboard'
import LinkAccountDashboard from './link/LinkAccountDashboard'
import PayeeDashboard from './payee/PayeeDashboard'
import ArchiveDashboard from './archive/ArchiveDashboard'
import MembershipDashboard from './membership/MembershipDashboard'
import store from '../store'
import { loadUser, logout } from '../actions/auth'

class App extends Component {
  componentDidMount() {
    try {
      store.dispatch(loadUser())
    } catch (err) {
      store.dispatch(logout())
    }
  }

  render() {
    return (
      <Provider store={store}>
        <ThemeWrapper>
          <CssBaseline />
          <Router>
            <Fragment>
              <ErrorBoundary>
                <AuthLoading>
                  <Idle />
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Header>
                      <Alerts />
                      <Message />
                      <ToastWrapper />
                      <Grid container>
                        <Grid item xs={12}>
                          <Routes>
                            <Route
                              exact
                              path="/"
                              element={<PrivateRoute exact path="/" component={Dashboard} />}
                            />
                            <Route
                              exact
                              path="/dashboard"
                              element={
                                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                              }
                            />
                            <Route
                              exact
                              path="/budget"
                              element={
                                <PrivateRoute exact path="/budget" component={BudgetDashboard} />
                              }
                            />
                            <Route
                              exact
                              path="/recurring"
                              element={
                                <PrivateRoute exact path="/recurring" component={Recurring} />
                              }
                            />
                            <Route
                              exact
                              path="/history"
                              element={<PrivateRoute exact path="/history" component={History} />}
                            />
                            <Route
                              exact
                              path="/accounts"
                              element={
                                <PrivateRoute
                                  exact
                                  path="/accounts"
                                  component={TransactionDashboard}
                                />
                              }
                            />
                            <Route
                              exact
                              path="/accounts/:accountKey"
                              element={
                                <PrivateRoute
                                  exact
                                  path="/accounts/:accountKey"
                                  component={TransactionDashboard}
                                />
                              }
                            />
                            <Route
                              exact
                              path="/holdings/:holdingKey"
                              element={
                                <PrivateRoute
                                  exact
                                  path="/holdings/:holdingKey"
                                  component={HoldingDetail}
                                />
                              }
                            />
                            <Route
                              exact
                              path="/reports"
                              element={
                                <PrivateRoute exact path="/reports" component={ReportDashboard} />
                              }
                            />
                            <Route
                              exact
                              path="/settings"
                              element={
                                <PrivateRoute
                                  exact
                                  path="/settings"
                                  component={SettingsDashboard}
                                />
                              }
                            />
                            <Route
                              exact
                              path="/link-accounts"
                              element={
                                <PrivateRoute
                                  exact
                                  path="/link-accounts"
                                  component={LinkAccountDashboard}
                                />
                              }
                            />
                            <Route
                              exact
                              path="/payees"
                              element={
                                <PrivateRoute exact path="/payees" component={PayeeDashboard} />
                              }
                            />
                            <Route
                              exact
                              path="/archives"
                              element={
                                <PrivateRoute exact path="/archives" component={ArchiveDashboard} />
                              }
                            />
                            <Route
                              exact
                              path="/membership"
                              element={
                                <PrivateRoute
                                  exact
                                  path="/membership"
                                  component={MembershipDashboard}
                                />
                              }
                            />
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/auth/google" element={<AuthGoogle />} />
                            <Route exact path="/register" element={<Register />} />
                            <Route
                              exact
                              path="/register-acknowledge"
                              element={<RegisterAcknowledge />}
                            />
                            <Route exact path="/reset-password" element={<ResetPassword />} />
                            <Route
                              exact
                              path="/reset-password-acknowledge"
                              element={<ResetPasswordAcknowledge />}
                            />
                            <Route
                              exact
                              path="/password/reset/confirm/:uid/:token"
                              element={<ResetPasswordConfirm />}
                            />
                            <Route exact path="/activate/:uid/:token" element={<Activate />} />
                            <Route path="*" element={<Error404 />} />
                          </Routes>
                        </Grid>
                      </Grid>
                    </Header>
                  </LocalizationProvider>
                </AuthLoading>
              </ErrorBoundary>
            </Fragment>
          </Router>
        </ThemeWrapper>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('app'))
