import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Typography, Divider, Grid } from '@mui/material'
import { toast } from 'react-toastify'

import { withRouter } from '../utilities/withRouter'
import { activate } from '../../actions/auth'
import AuthLayout from './AuthLayout'
import { sharedStyles } from '../common/styles'

const styles = theme => ({
  ...sharedStyles(theme)
})

class Activate extends Component {
  componentDidMount() {
    this.activateAccount()
  }

  activateAccount = e => {
    try {
      const {
        match: { params }
      } = this.props

      if (typeof params !== 'undefined' && params != null) {
        const uid = params.uid
        const token = params.token
        this.props.activate(uid, token)
        toast.success('Account activated.')
      } else {
        toast.error('Unable to activate account at this time.')
      }
    } catch (error) {
      toast.error('Unable to activate account at this time.')
    }
  }

  render() {
    const { classes, isAuthenticated, accountActivated } = this.props

    if (isAuthenticated) return <Navigate to="/" />
    if (accountActivated) return <Navigate to="/login" />

    return (
      <AuthLayout heading="Welcome!">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Thank you for signing up! Your account is now active. You can log in and start
              creating your new budget.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid container item xs={12} justifyContent="center" className={classes.pt1}>
            <Typography variant="body2" noWrap>
              <Link to="/login" className={classes.textLinks}>
                Sign in
              </Link>
            </Typography>
          </Grid>

          <Divider />
        </Grid>
      </AuthLayout>
    )
  }
}

Activate.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  accountActivated: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  accountActivated: state.authReducer.accountActivated
})

const mapDispatchToProps = {
  activate
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Activate)))
