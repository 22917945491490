import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const PROXY = process.env.REACT_APP_PROXY
const REDIRECT_PROXY = process.env.REACT_APP_REDIRECT_PROXY

const continueWithSocialAuth = async (provider, redirect) => {
  try {
    const url = `${PROXY}/auth/o/${provider}/?redirect_uri=${REDIRECT_PROXY}/${redirect}`
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include'
    })
    const data = await res.json()

    if (res.status === 200 && typeof window !== 'undefined') {
      window.location.replace(data.authorization_url)
    } else {
      toast.error('Ope! Something went wrong.')
    }
  } catch (err) {
    toast.error('Ope! Something went wrong.')
  }
}

continueWithSocialAuth.propTypes = {
  provider: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired
}

export default continueWithSocialAuth
